@import "onetrust-banner.css";

html,
body {
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
  scrollbar-gutter: stable;
}

#__next {
  position: relative;
  background-color: #ffffff;
}

/*
These are the global styles of google maps autosuggest.
*/
:root {
  --google-suggest-width: 400px;
}
.pac-container {
  width: var(--google-suggest-width) !important;
  min-width: var(--google-suggest-width) !important;
  border-radius: 0;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  z-index: 10000;
}
.pac-container::after {
  margin: 8px 16px 8px 0;
}
.pac-container .pac-icon {
  display: none;
}
.pac-container .pac-item {
  padding: 0 16px;
  border: 0 none;
}
.pac-container .pac-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.pac-container .pac-item,
.pac-container .pac-matched,
.pac-container .pac-item-query {
  line-height: 40px;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  font-family: Arial, serif;
}
.pac-container .pac-matched {
  font-weight: 600;
}
.pac-container .pac-item-selected {
  background-color: rgba(0, 0, 0, 0.08);
}

#ot-sdk-btn.ot-sdk-show-settings {
  border: none !important;
  color: #fff !important;
  font-size: inherit !important;
  background: #000;
}

#ot-sdk-btn.ot-sdk-show-settings:hover {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

@font-face {
  font-family: "Arial Black";
  src:
    url("/assets/fonts/arial-black-woff2.woff2") format("woff2"),
    url("/assets/fonts/arial-black-woff.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Primark Basis";
  src:
    url("/assets/fonts/PrimarkBasis-Regular.woff2") format("woff2"),
    url("/assets/fonts/PrimarkBasis-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Primark Basis";
  src:
    url("/assets/fonts/PrimarkBasis-Bold.woff2") format("woff2"),
    url("/assets/fonts/PrimarkBasis-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
