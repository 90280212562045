#onetrust-banner-sdk:focus {
  outline: 2px solid #3377ff !important;
  outline-offset: -4px !important;
  opacity: 1 !important;
}

#onetrust-banner-sdk .ot-sdk-container {
  width: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}

#onetrust-banner-sdk .ot-sdk-container .ot-sdk-row {
  display: flex !important;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 16px;
}

#onetrust-banner-sdk .ot-sdk-container .ot-sdk-row:after {
  display: none !important;
}

#onetrust-banner-sdk .ot-sdk-row #onetrust-group-container {
  width: auto !important;
  flex: 0 0 auto;
}

#onetrust-banner-sdk #onetrust-group-container #onetrust-policy {
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

#onetrust-banner-sdk #onetrust-policy #onetrust-policy-title {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #000000;
}

#onetrust-banner-sdk #onetrust-policy #onetrust-policy-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 0;
}

#onetrust-banner-sdk #onetrust-policy #onetrust-policy-text > a {
  font-weight: normal !important;
  box-decoration-break: clone;
  letter-spacing: 0.02em;
  margin-left: 3px;
  padding: 0 2px;
}

#onetrust-banner-sdk .ot-sdk-row #onetrust-button-group-parent {
  margin: 28px 0 0 0 !important;
  transform: unset !important;
  position: unset !important;
  top: unset !important;
  left: unset !important;
  width: auto !important;
  padding: 0 !important;
  flex: 0 0 auto;
}

#onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group {
  width: auto !important;
  flex-direction: column-reverse !important;
  display: flex !important;
  align-items: center;
  row-gap: 16px;
  margin: 0;
}

#onetrust-banner-sdk #onetrust-button-group #onetrust-reject-all-handler,
#onetrust-banner-sdk #onetrust-button-group #onetrust-accept-btn-handler {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin: 0 !important;
  min-height: 48px !important;
  padding: 8px 16px !important;
  border: 2px solid #000000 !important;
  border-radius: 0 !important;

  font-style: normal;
  font-family: "Arial Black", Arial;
  font-weight: 900 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-align: center;
}

#onetrust-banner-sdk #onetrust-button-group #onetrust-pc-btn-handler {
  width: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 20px !important;
  color: #000000 !important;
  border-radius: 0 !important;

  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.02em;
}

#onetrust-banner-sdk #onetrust-button-group #onetrust-reject-all-handler:focus,
#onetrust-banner-sdk #onetrust-button-group #onetrust-accept-btn-handler:focus {
  outline: 2px solid #3377ff !important;
  outline-offset: 4px !important;
  opacity: 1 !important;
}

#onetrust-banner-sdk #onetrust-policy #onetrust-policy-text > a:focus,
#onetrust-banner-sdk #onetrust-button-group #onetrust-pc-btn-handler:focus {
  text-decoration: underline !important;
  outline: 2px solid #3377ff !important;
  outline-offset: 0px !important;
  opacity: 1 !important;
}

#onetrust-banner-sdk
  #onetrust-button-group
  #onetrust-reject-all-handler:not(:focus):hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
  opacity: 1 !important;
}

#onetrust-banner-sdk
  #onetrust-button-group
  #onetrust-accept-btn-handler:not(:focus):hover {
  background-color: rgba(0, 0, 0, 0.75) !important;
  border-color: transparent !important;
  opacity: 1 !important;
}

#onetrust-banner-sdk #onetrust-policy #onetrust-policy-text > a:not(:focus):hover,
#onetrust-banner-sdk
  #onetrust-button-group
  #onetrust-pc-btn-handler:not(:focus):hover {
  text-decoration: underline !important;
  color: rgba(0, 0, 0, 0.75) !important;
  opacity: 1 !important;
}

@media only screen and (min-width: 768px) {
  #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row {
    padding: 24px 32px;
  }

  #onetrust-banner-sdk #onetrust-policy #onetrust-policy-title {
    font-size: 26px !important;
    line-height: 32px !important;
  }

  #onetrust-banner-sdk #onetrust-policy #onetrust-policy-text {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group {
    width: auto !important;
    display: grid !important;
    grid-gap: 20px 16px !important;
    grid-template-rows: repeat(2, auto) !important;
    grid-template-columns: repeat(2, auto) !important;
    justify-items: center !important;
  }

  #onetrust-banner-sdk #onetrust-button-group #onetrust-reject-all-handler {
    height: 56px !important;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  #onetrust-banner-sdk #onetrust-button-group #onetrust-accept-btn-handler {
    height: 56px !important;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  #onetrust-banner-sdk #onetrust-button-group #onetrust-pc-btn-handler {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }
}

@media only screen and (min-width: 1153px) {
  #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row {
    padding: 24px 64px !important;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  #onetrust-banner-sdk .ot-sdk-row #onetrust-group-container {
    flex: 0 0 45%;
  }

  #onetrust-banner-sdk #onetrust-group-container #onetrust-policy {
    row-gap: 8px;
  }

  #onetrust-banner-sdk .ot-sdk-row #onetrust-button-group-parent {
    margin: 0 !important;
  }
}
